.nav-link a {
    color: #939292;
    font-weight: 600;
}

.nav-link:hover {
    background-color: #292929;
    cursor: pointer;
}

.nav-link:hover a {
    color: #a7d62e;;
}