.banner {
    background-color: #bfff00;
}

.banner h1.banner-title {
    color: #5f378d;
}

.banner p.banner-text {
    color: #6b6b6b;
}

.banner button.create-account {
    background-color: #5f378d;
}