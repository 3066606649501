@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Squared Display';
    src: url('../public/fonts/Squared\ Display.ttf');
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

body {
    background-color: #090a08;
    font-family: 'Montserrat', sans-serif;
    width: 100vw;
    overflow-x: clip;
}

a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
}

.gl {
    background: rgba(255, 255, 255, 0.23);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px); 
    -webkit-backdrop-filter: blur(8px);
}