
.hero-text__sub-header {
    color: #9dcb2f;
    font-size: 1.2rem;
    font-weight: 100;
}

.hero-text__header {
    color: #fff;
    font-weight: 600;
    line-height: 1.15;
}

.hero-text__paragraph {
    color: #c2fb38;
}


.hero-image__img {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    z-index: -1;
}

.hero-image > svg {
    width: 100%;
    height: 100%;
}