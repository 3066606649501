.about-img {
    height: 800px;
}

.about-img svg {
    transform: translateX(-50%);
}

.about-text p {
    color: #fff;
}