footer .logo {
    color: #5f378d;
    font-size: 1.7rem;
    font-family: 'Roboto', sans-serif;
}

footer .follow p {
    color: #6b6b6b;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
}