.section-title__bg {
    top: 0;
    color: #36015e6d;
    font-size: 22rem;
    line-height: 15rem;
    letter-spacing: -25px;
    transform: translateY(-40%);
    z-index: -1;
    font-family: 'Squared Display', sans-serif;
    text-align: center;
}

.section-title__text::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 80%;
    height: 100%;
    border-radius: 100%;
    background-color: #b3ff00a5;
    filter: blur(3rem);
    opacity: 0.5;
    transform: translateX(-50%);
}

.section-title__text {
    color: #affe00;
    font-size: 32px;
    text-align: center;
}