.benefit {
    position: relative;
}

.benefit::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120%;
    height: 120%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    filter: blur(5em);
    z-index: -1;
}

.green-glow.benefit::before {
    background-color: #b2fe003d;
}

.pink-glow.benefit::before {
    background-color: #982f9c6f;
}